.Reporting {
  display: grid;
  grid-gap: 10px;

  article {
    background-color: red;
  }
}

@media screen and (min-width: 768px){
  .my-reports {
    grid-column: 1 / 2;
    grid-row: 1;
  }
  
  .latest-reports {
    grid-column: 2 / 3;
    grid-row: 1;
  }
  
  .monthly-updates {
    grid-column: 1;
    grid-row: 2;
  }
  
  .special-reports {
    grid-column: 2;
    grid-row: 2;
  }
  
  .comprehensive-reports {
    grid-column: 1/3;
    grid-row: 3
  }
}