.BrandAwareness {
  display: grid;
  padding: 10px;
  .header {
    grid-row: 1;
    grid-column: 1 / 2;
  }
  .total-awareness {
    grid-row: 2;
    grid-column: 1;
  }
  
  .month-awareness {
    grid-row: 3;
    grid-column: 1;
  }
  
  .chart {
    grid-row: 2 / 4;
    grid-column: 2;
  }
}
.percentage-item {
  display: flex;
  flex-direction: row;
  .percentage {
    margin-right: 10px;
    font-size: 28px;
  }

  .text {
    align-self: center;
  }
}