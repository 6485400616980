.Cannapanel-container {
  flex: 1 0 auto;
  display: grid;
  padding: 10px;
  article {
    // max-width: 100%;
  }
}
@media screen and (min-width: 768px){
  .Cannapanel-container {
    grid-gap: 10px;
    grid-template-rows: auto auto 1fr;
    grid-template-columns: 1fr 1fr;
    .story-info {
      grid-column-start:1;
      grid-column-end: 1;
      grid-row-start: 1;
      grid-row-end: 1;
      display: flex;
      flex: 1 0 auto;
    }
    
    .customer-segment {
      grid-column-start:2;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 3;
    }
    
    .brand-awareness {
      grid-column-start:1;
      grid-column-end: 1;
      grid-row-start: 2;
      grid-row-end: 2;
    }
    
    .forecasting {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 3;
      grid-row-end: 3;
    }
  }
}