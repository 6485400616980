@import "../../global.scss";

.landing-page-container {
  section {
    padding: 20px 20px 20px 20px;
  }
}
.gga-section {
  display: flex;
  flex-direction: column;

  &.jumbotron {
    background-image: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ),  url("/assets/images/slider-bg.png");
  }
}

.product-slider {
  height: 400px;
  width: 100%;
  &.slider {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .slider-item {
      text-align: center;
      p {
        display: block;
        padding: 10px;
      }
      &:nth-child(1) {
        background-color: red;
      }
      &:nth-child(2) {
        background-color: blue;
      }
      &:nth-child(3) {
        background-color: yellow;
      }
    }
  }
}
.gga-membership {
  background-color: #6d6d6d;
  h2,
  p {
    color: white;
  }

  .btn-title {
    margin: 20px 0px 10px;
    align-self: center;
  }
  .btn {
    align-self: center;
  }
}
.cannapanel-img {
  align-self: center;
  width: 364px;
}

.service-item-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.service-item {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  i {
    margin: 0px;
    font-size: 60px;
  }
  h4 {
    // align-self:center;
    margin: 0px;
    font-size: 16px;
    text-align: center;
    // display: none;
  }
  p {
    margin-bottom: 0px;
    font-size: 13px;
  }
}
@media only screen and (min-width: $tablet-size) {
  .service-item {
    width: 150px;
  }
  .gga-membership {
    display: grid;
    grid-template-rows: 100px auto 60px 1fr;
    .cannapanel-img {
      grid-column: 2;
      grid-row: 1 / span 4;
      padding-top: 30px;
    }

    .btn-title {
      margin: 0px;
    }

    .btn {
      justify-self: center;
    }
  }
}
