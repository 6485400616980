.modal-content {
  ul.packages-list {
    padding-left: 30px;
    text-align: left;
    list-style-type: circle;
  
    li {
      list-style-type: circle;
    }
  }
}

@media only screen and (min-width: 601px){
  .product-cards {
    .card-content {
      span {
        text-align: center;
      }
      div {
        height: 153px;
        text-align: center;
      }
    }
  }
}

@media only screen and (min-width: 768px){
  .product-cards {
    .card-content {
      div {
        height: 113px;
      }
    }
  }
}