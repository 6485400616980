.StoryInfoSummary {
  display: grid;
  padding: 10px;
  grid-gap: 10px;

  .title {
    grid-column: 1/3;
    grid-row: 1;
    align-self: center;
  }

  .image {
    grid-column: 1;
    grid-row: 1/3;
    align-self: center;
  }

  .description {
    grid-column:2;
    grid-row:2;
  }
}

.title {
  text-align: center;
}

.image {
  width: 90px;
}
