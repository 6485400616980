@import '../../global.scss';

.login-container {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url('/assets/images/slider-bg.jpg');
}

.login-form {
  padding: 20px;
  display: flex;
  flex-direction: column;
  h2 {
    text-align: center;
  }
  .btn {
    align-self: center;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: $tablet-size){
  .login-container {
    align-items: center;
    justify-content: center;
    .login-form {
      padding: 60px;
      width: 500px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}