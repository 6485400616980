.Awareness {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .competitors {
    .list-item {
      width:100px;
    }
  }
}
