.app-container {
  width: inherit;
  height: inherit;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}
main {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;

  & > div {
    & > section {
      padding: 20px;
    }
  }
}

.card-image {
  width: 90%;
  padding-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.card-section {
  // border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}
p {
  font-family: 'Roboto', sans-serif;
  color: #5B5B5B;
  margin: .5rem 0 .5rem 0;
}

h2 {
  font-size:28px;
}

h3 {
  font-size: 17px;
}

h1, h2, h3, h4, h5, h6{
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

nav {
  // box-shadow: none !important;
}
// @media screen and (min-width: $max-desktop-size){
//   html {
//     display: flex;
//     flex: 0 0 auto;
//     justify-content: center;
//   }
//   body {
//     width: $max-desktop-size;
//   }
// }
button {
  cursor: pointer;
  &, &:focus {
    background-color: transparent
  }
}

.btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.btn {
  cursor: pointer;
  background: #2A8C1D;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 1px;
  // padding: 14px 35px;
  text-transform: uppercase;
  border-radius: 2px;
  transition: all 0.2s ease;
}

.gga-dark-section {
  background-color: #6d6d6d;
  h2, p {
    color: white;
  }

  .btn-title {
    margin: 20px 0px 10px;
    align-self: center;
  }
  .btn {
    align-self: center;
  }
}

// @media only screen and (min-width: $max-desktop-size){
//   main {
//     & > div {
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       & > section {
//         width: 900px;
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         align-items: center;
//       }
//     }
//   }
// }