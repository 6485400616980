.ProductPortfolio {

}
@media screen and (min-width: 768px){
  .ProductPortfolio {
    display: grid;
    padding: 10px;
    .header {
      grid-row: 1;
    }
    .charts {
      display: grid;
      width: 300px;
      &:nth-child(odd) {
        grid-column: 2;
      }
      &:nth-child(even){
        grid-column: 1;
      }
      .chartjs-size-monitor {
        width: inherit !important;
      }
      canvas {
        width: inherit !important;
        max-width: 400px !important;
        max-height: 200px !important;
      }
    }
  }
}