

.card-team-title {
  font-weight: 600;
}

.card-team-description {
  padding-top: 5px;
}

.card--email {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  h2 {
    text-align: center;
  }
  .form-group {
    width: 100%;
    // background: #F6F8FA;
  }
}

.form-body {
  height: 135px;
  border: 1px solid #EEF2F6;
  box-shadow: none;
}