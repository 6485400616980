.Dashboard {
  padding: 10px;
  // article {
    //   background-color: red;
    // }
  }
  
  @media screen and (min-width: 768px){
    .Dashboard {
      display: grid;
      grid-gap: 10px;
    .competitive-overview {
      grid-column: 1;
      grid-row: 1;
    }
    
    .awareness {
      grid-column: 2;
      grid-row: 1;
    }
    
    .pricing-analysis {
      grid-column: 1;
      grid-row: 2;
    }
    
    .product-portfolio {
      grid-column: 2;
      grid-row: 2;
    }
  }
}