.edit-blog-container {
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.edit-blog-row {
  margin: 15px 0px;
  
  &.btn-container {
    display: flex;
    justify-content: space-evenly;
  }
}

@media screen and (min-width: 768px){
  .edit-blog-container {
    align-items: center;
  }
  .edit-blog-row {
    width: 600px;
  }
}