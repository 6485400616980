header .navbar-default {
  background: none;
  border: 0px;
}
header .navbar-default .navbar-nav li a {
  color: #333333;
  font-size: 15px;
}
font header .navbar-default .navbar-nav li a:hover {
  color: #000;
}

header {
  height: 75px;
  min-height: 75px;
  background: transparent;
  height: 75px;
  display: flex;
  flex-direction: column;
  flex: 0 0;
}
.navbar {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  height: inherit;
  border: 0px;

  &.collapsed {
    .navbar-header {
      border-bottom: none;
    }
    .navbar-nav {
      height: 0px;
      li {
        height: 0px;
        a {
          height: 0px;
        }
      }
    }
  }
  .navbar-header {
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    margin: 0px 8px;
    padding: 7px;
    border-bottom: 1px solid #ddd;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
.nav-container {
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
  padding: 0px 20px;
  transition: 2s;
  z-index: 100;
  background-color: white;
@media screen and (max-width: 840px){
  &.hidden {
    max-height: 0;
    overflow: hidden;

    .navbar-nav {
      height: 0;
    }
  }
}
}
.navbar-nav {
  margin: 0px;
  padding: 0px;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  list-style: none;
  li {
    &.selected {
      a span {
        font-weight: 600;
      }
    }
  }
}

.nav-link {
  &:visited,
  &:-webkit-any-link {
    text-decoration: none;
  }
}
.navbar-brand {
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  height: 60px;
}

.logo-title-container {
  height: 60px;
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  padding-left: 3px;
}
.navbar-toggle {
  border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
  border-style: solid;
  border-width: 1px;
  padding: 1px 7px 2px;
  cursor: pointer;
  height: 32px;
  align-self: center;
  .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    background-color: #888;

    & + .icon-bar {
      margin-top: 4px;
    }
  }
}

//Tablet view
@media screen and (min-width: 840px) {
  .nav-container.hidden {
    display: flex;
  }
  .logo-title-container {
    margin-right: 20px;
    justify-content: flex-start;
  }
  .navbar {
    flex-direction: row;
    .navbar-header {
      flex-direction: row;
      justify-content: flex-start;
      border-bottom: none;
      .navbar-toggle {
        display: none;
      }
    }
    .navbar-nav {
      height: 75px;
      flex: 1 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;

      li {
        height: inherit;
        a {
          height: inherit;
        }
      }
    }
  }
}
