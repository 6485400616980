.membership-container {
  .gga-section {
    &.jumbotron-small {
      background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('/assets/images/puzzle_pieces.png');
      padding: 60px 40px 80px 40px;
      background-size: contain contain;
      -webkit-background-size: contain contain; /* For WebKit*/
      -moz-background-size: contain contain;    /* Mozilla*/
      -o-background-size: contain contain;      /* Opera*/
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: top center;
      display: flex;
      justify-content: center;
      align-items: center;
      .jumbotron-header {
        text-align: center;
        h1 {
          color: white;
          font-weight: 100;
          font-size: 60px;
          line-height: 80px;
        }
        h2 {
          color: white;
          font-weight: 100;
          font-size: 45px;
          line-height: 60px;
        }
        h3 {
          color: white;
          font-weight: 100;
          font-size: 30px;
          line-height: 40px;
        }
      }
    }
  }
}