footer {
  height: 100px;
  // display: flex;
  // flex-direction: row;
  // justify-content: space-evenly;
  background-color: #F5F5F5;
  padding: 20px;
}

.footer-container {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-icons {
  flex: 1 0 auto;
  height: 20px;
  display: flex;
  flex-direction: row;

  img {
    cursor: pointer;
    margin-right: 20px;
    height: inherit;
  }
}

.footer-body {
  width: 100%;
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.footer-item-container {
  // flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.sign-up-container {
  p {
    text-align: center;
  }
}