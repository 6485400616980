.CustomerSegmentation {
  display: grid;

}

@media screen and (max-width: 768px) {
  .pie-chart {
    max-width: 100vw;
  } 
}
@media screen and (min-width: 768px){
  .CustomerSegmentation {
    padding: 10px;
    .header {
      grid-column: 1/3;
      grid-row: 1;
    }
  
    .segment {
      grid-row: 2;
  
      &.current {
        grid-column: 1;
      }
  
      &.overall {
        grid-column: 2;
      }
    }
    .recommendation {
      grid-row: 3;
      grid-column: 1 / 3;
    }  
  }
}

.header {
  text-align: center;
}
.segment-header {
  text-align: center;
}

.recommendation {
  .list {
    .list-item {
      margin: 10px 0px;
      list-style-type: circle;
    }
  }
}