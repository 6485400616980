@import '../../global.scss';


.blogs-container {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: center;
  // flex-wrap: wrap;
  // padding: 20px;

  .btn {
    width: 300px;
  }
}

.blog-container {
  // padding: 10px 40px;
  // display: flex;
  // flex: 1 0 auto;
  // flex-direction: column;

  .blog-underline {
    border: 0;
    height: 1px;
    width: 100%;
    background-color: #ddd;
  }

  .blog-body {
    display: flex;
    flex: 1 0 auto;
  }
  .blog-date {
    color: grey;
  }
}
@media screen and (min-width: 768px) {
  .blogs-element-container {
    height: 100%;
    width: 100%;
    justify-content: space-between;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 40px;
    padding: 40px;
    justify-items: center;
    align-items: center;  
  }
}