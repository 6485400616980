.customer-surveys-container {
  // background-color: #ddd;
  .gga-section {
    &.jumbotron {
      background-image: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url('/assets/images/customer_colors.png');
      padding: 60px 40px 80px 40px;
      background-size: contain contain;
      -webkit-background-size: contain contain; /* For WebKit*/
      -moz-background-size: contain contain;    /* Mozilla*/
      -o-background-size: contain contain;      /* Opera*/
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: top center;
      display: flex;
      justify-content: center;
      align-items: center;
      .jumbotron-header {
        text-align: center;
        h1 {
          color: white;
          font-weight: 100;
          font-size: 60px;
          line-height: 80px;
        }
        h2 {
          color: white;
          font-weight: 100;
          font-size: 45px;
          line-height: 60px;
        }
        h3 {
          color: white;
          font-weight: 100;
          font-size: 30px;
          line-height: 40px;
        }
      }
    }

    &.section-2 {
      .dropdown-container {
        .dropdown-items {
          // height: 60px;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          flex: 1 1 60px;;
          p {
            display: block;
            overflow-y: hidden;
            // max-height: 500px; /* approximate max height */
          
            transition-property: all;
            transition-duration: .5s;
            transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
            margin-top: 0px;
          }

          &.closed {
            p {
              // max-height: 0;
              margin-top: -500px;
            }
          }
        }
      }
    }

  }
}
