.PricingAnalysis {
  padding: 10px;
  .grade {
    display: grid;
    grid-gap: 10px;
    .name {
      grid-column: 1;
      justify-self: flex-end;
    }
    .value {
      grid-column:2;
    }

  }
}