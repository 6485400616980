html {
  height: 100%;
  width: 100%;
}

body {
  margin: 0px;
  height: inherit;
  width: inherit;
  display: flex;
  flex-direction: column;
  font-family: "Open Sans", sans-serif;
  & > .ember-view {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  justify-content: center;
  align-items: center;
}

#root {
  height: inherit;
  width: inherit;
  display: flex;
  flex-direction: column;
  max-width: 1024px;
}
* {
  font-family: "Roboto", sans-serif;
}
.gga-section {
  &.jumbotron {
    padding: 80px 20px 80px 20px;
    background-size: contain contain;
    -webkit-background-size: contain contain; /* For WebKit*/
    -moz-background-size: contain contain;    /* Mozilla*/
    -o-background-size: contain contain;      /* Opera*/
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: top center;
    display: flex;
    justify-content: center;
    align-items: center;
    .jumbotron-header {
      h1 {
        color: white;
        font-weight: 100;
        font-size: 60px;
        line-height: 80px;
      }
      h2 {
        color: white;
        font-weight: 100;
        font-size: 45px;
        line-height: 60px;
      }
      h3 {
        color: white;
        font-weight: 100;
        font-size: 30px;
        line-height: 40px;
      }
    }
  }
}
.slider {
  width: inherit;
  height: inherit;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  
  // -webkit-scroll-snap-points-x: repeat(400px);
  // -ms-scroll-snap-points-x: repeat(400px);
  scroll-snap-points-x: repeat(400px);
  // -webkit-scroll-snap-type: mandatory;
  // -ms-scroll-snap-type: mandatory;
  scroll-snap-type: mandatory;
  // -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;

  .slider-item {
    width: inherit;
    height: inherit;
    // flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
// @media only screen and (max-width: 1024px){
//   #root {
//     width: 1024px;
//     height: 1024px;
//   }
// }

.card {
  .card-action  {
      a {
        cursor: pointer;
      }
  }
}