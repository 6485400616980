.CompetitiveOverview {
  display: grid;
  .competitor-info {
    grid-row: 3;
  }
  .list {
    grid-row: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
      li {
        cursor: pointer;
        &.active.list-item {
          text-decoration: underline;
        }
      }
}
@media screen and (min-width: 768px){
  .CompetitiveOverview {
    display: grid;
    padding: 10px;
    .competitor-info {
      grid-column: 1;
      grid-row: 2;
    }
  
    .list {
      grid-column: 2;
      grid-row: 1/3;
      align-self: center;
  
    }
  }

}