.blog-post {    
  // max-height: 250px;
  // max-width: 650px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 200px;
  max-height: 300px;
  overflow: hidden;

}

.blog-post img{
  margin-bottom: 15px;
}
.blog-post p{
  margin: 15px 0 20px;
}

.blog-post-summary {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.post-title{
  color: #655E7A;
  font-size: 25px;
  font-weight: 600;
  display: block;
  margin-bottom: 10px;
}

@media screen and (min-width: 768px){
  .blog-post {
    flex: 0 1 300px;
  }
}